.typing-challenge-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.details-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.typewriter-container {
    display: flex;
    flex-grow: 1;
}