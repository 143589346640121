.nav-container {
    display: flex;
    height: 72px;
    align-items: center;
    background: #1d2020;
    margin: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, 0.26);
    justify-content: space-between;
    z-index: 99;
}

.nav-left {
    display: flex;
    align-items: center;
}

.nav-right {
    margin-right: 40px;
}

.flash-logo {
    margin-left: 20px;
    height: 2.5rem;
}

.flash-logo-text {
    font-family: 'Bangers', cursive;
    font-size: 3rem;
    letter-spacing: 1px;
    margin: 0 10px;
    color: #e7e6e7;
}

.nav-page-link {
    font-size: 32px;
    color: #e7e6e7;
    font-weight: 800;
}

@media only screen and (max-width: 640px) {

    .flash-logo {
        height: 1.75rem;
    }

    .nav-page-link {
        font-size: 1.25rem;
    }

    .flash-logo-text {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 300px) {
    .nav-page-link {
        font-size: 1rem;
    }

    .flash-logo-text {
        font-size: 2rem;
    }
}