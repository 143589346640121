.details-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, 0.26);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.details-card-container:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, .12), 0 10px 10px rgba(0, 0, 0, 0.26);
}

.card-name {
    font-size: 15px;
    margin-bottom: -15px;
}

.card-value {
    font-size: 50px;
    font-weight: 800;
    margin: 0;
}