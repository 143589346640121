.challengeSection-container {
min-height: 90vh;
flex-direction: column;
align-items: center;
margin: 25px 0;
}

.challengeSection-header {
    text-align: center;
    color: #fbf6fb;
    font-family: 'Bangers', cursive;
    font-size: 64px;
    letter-spacing: 5px;
}