.landing-container {
    display: flex;
    min-height: 90vh;
    justify-content: space-between;
    align-items: center;
    color: #eee2ee;
    padding: 0 32px;
    font-family: 'Bangers', cursive;
    flex-wrap: wrap;
}

.landing-header {
    font-size: 92px;
    letter-spacing: 5px;
    margin: 0;
}

.typewriter-container {
    font-size: 64px;
    letter-spacing: 5px;
}

.flash-image {
    width: 40vw;
}

@media (max-width: 800px) {
    .landing-container {
        flex-direction: column;
        align-items: center;
    }

    .flash-image {
        width: 80vw;
    }

    .landing-header {
        text-align: center;
        margin-top: 32px;
    }

    .typewriter-container {
        text-align: center;
    }
}