.try-again-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.try-again-container > h1 {
    text-decoration: underline;
    margin: 0;
    font-size: 36px;
    margin-bottom: 20px;
}

.result-container > p {
    font-size: 20px;
    margin: 7px;
}

.end-buttons {
    position: relative;
    margin: 12px;
    border: none;
    border-radius: 4px;
    min-width: 64px;
    padding: 2px 16px;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, 0.26);
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.start-again-btn {
    background-color: #3e4248;
}

.start-again-btn:hover, 
.start-again-btn:focus {
    background: #808080;
}

.share-btn {
    background: #3b5998;
}

.share-btn:hover, 
.share-btn:focus {
    background: #5d7dc4;
}

.tweet-btn {
    background: #1da1f2;
}

.tweet-btn:hover, 
.tweet-btn:focus {
background: #8faef3;
}
.details-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, 0.26);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.details-card-container:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, .12), 0 10px 10px rgba(0, 0, 0, 0.26);
}

.card-name {
    font-size: 15px;
    margin-bottom: -15px;
}

.card-value {
    font-size: 50px;
    font-weight: 800;
    margin: 0;
}
.test-letter {
    font-size: 19px;
    line-height: 19px;
}

.test-letter-correct {
    color: #558b2f;
}

.test-letter-incorrect {
    color: #bf360c;
}

.test-letter-not-attempted {
    color: #f9a825;
}
.typing-challenge {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.timer-container {
    margin: 16px;
}

.timer {
    font-size: 38px;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.timer-info {
    margin: 0;
    margin-top: -5px;
    color: #dd5044;
    font-size: 20px;
    text-align: center;
}

.textarea-container {
    margin: 16px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 80%;
}

.textarea-left, .textarea-right {
    display: flex;
    width: 50%;
    flex-grow: 1;
}

.textarea {
    text-align: left;
    flex-grow: 1;
    height: 400px;
    width: 100%;
    padding: 10px;
    line-height: 18px;
    flex-wrap: wrap;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, 0.26);
    overflow: scroll;
    transition: all 0.2s;
}

.textarea::-webkit-scrollbar {
    display: none;
}

.textarea {
    scrollbar-width: none;
}

.textarea:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, .12), 0 10px 10px rgba(0, 0, 0, 0.26);
}

.test-paragraph {
    font-size: 12px;
    background: #e9e7e4;
    padding: 12px;
}
.typing-challenge-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.details-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.typewriter-container {
    display: flex;
    flex-grow: 1;
}
.test-container {
    background-color: #fff;
    margin: 64px 0;
    margin-left: 10%;
    width: 80%;
    min-height: 800px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, 0.26);
}

.try-again-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;

}
.challengeSection-container {
min-height: 90vh;
flex-direction: column;
align-items: center;
margin: 25px 0;
}

.challengeSection-header {
    text-align: center;
    color: #fbf6fb;
    font-family: 'Bangers', cursive;
    font-size: 64px;
    letter-spacing: 5px;
}
.footer-container {
    display: flex;
    justify-content: center;
    background-color: #1d2020;
    margin: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, 0.26);
    z-index: 99;
}

.footer-link {
    font-size: 26px;
    color: #e7e6e7;
    margin: 10px;
    text-align: center;
}
.landing-container {
    display: flex;
    min-height: 90vh;
    justify-content: space-between;
    align-items: center;
    color: #eee2ee;
    padding: 0 32px;
    font-family: 'Bangers', cursive;
    flex-wrap: wrap;
}

.landing-header {
    font-size: 92px;
    letter-spacing: 5px;
    margin: 0;
}

.typewriter-container {
    font-size: 64px;
    letter-spacing: 5px;
}

.flash-image {
    width: 40vw;
}

@media (max-width: 800px) {
    .landing-container {
        flex-direction: column;
        align-items: center;
    }

    .flash-image {
        width: 80vw;
    }

    .landing-header {
        text-align: center;
        margin-top: 32px;
    }

    .typewriter-container {
        text-align: center;
    }
}
.nav-container {
    display: flex;
    height: 72px;
    align-items: center;
    background: #1d2020;
    margin: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, 0.26);
    justify-content: space-between;
    z-index: 99;
}

.nav-left {
    display: flex;
    align-items: center;
}

.nav-right {
    margin-right: 40px;
}

.flash-logo {
    margin-left: 20px;
    height: 2.5rem;
}

.flash-logo-text {
    font-family: 'Bangers', cursive;
    font-size: 3rem;
    letter-spacing: 1px;
    margin: 0 10px;
    color: #e7e6e7;
}

.nav-page-link {
    font-size: 32px;
    color: #e7e6e7;
    font-weight: 800;
}

@media only screen and (max-width: 640px) {

    .flash-logo {
        height: 1.75rem;
    }

    .nav-page-link {
        font-size: 1.25rem;
    }

    .flash-logo-text {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 300px) {
    .nav-page-link {
        font-size: 1rem;
    }

    .flash-logo-text {
        font-size: 2rem;
    }
}


body {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.app {
    display: flex;
    flex-direction: column;
    background-color: #262a2b;
}


